.foracst-container {
    .forcast-title {
        margin-bottom: 7rem;
    }
    .forcast-card {
        position: relative;
        // box-shadow: 0px 0px 30px -5px rgba(0, 0, 0, 0.15);
        background: $secondary;
        border: 1px solid #ededed;
        border-radius: 10px;
        text-align: center;
        padding: 2rem 12rem;
        margin-bottom: 0;
        margin-top: 6rem;
        @media only screen and (max-width: $medium) {
            padding: 2rem 4rem;
        }
        &::before {
            content: "";
            height: 99px;
            border-left: 4px dotted $primary;
            position: absolute;
            top: -99px;
            z-index: -1;
        }
        .forcast-date {
            position: absolute;
            top: -12px;
            left: 50%;
            transform: translate(-50%, 0);
            width: 100%;
            span {
                background: $primary;
                color: #fff;
                padding: 2px 5px;
                border-radius: 5px;
            }
        }
        &.active {
            border: 2px solid $primary;
            .forcast-date {
                span {
                    background-color: $primary;
                    color: white;
                }
            }
        }
        @media only screen and (max-width: $small) {
            padding: 2rem 2rem;
        }
    }
}
