.video-container {
    position: relative;
    overflow: hidden;
    height: 350px;
    @media only screen and (max-width: 630px) {
        height: auto;
    }
    .video {
        width: 100%;
        height: auto;
    }
    .header-container {
        position: absolute;
        top: 0;
        background-color: rgba(0, 0, 0, 0.7);
        height: 100%;
        display: flex;
        align-items: center;
        .header-inner-contents {
            @media only screen and (max-width: 500px) {
                h2 {
                    font-size: 1.2rem;
                }
                p {
                    font-size: 0.9rem;
                }
            }
        }
    }
}
@media only screen and (max-width: $medium) {
    .header-container {
        padding: 2rem 0 !important;
        .chakra-container > div {
            display: flex;
        }
    }
}
